(function () {
    'use strict';

    angular
        .module('app.settings.userRights')
        .controller('UserRightsController', UserRightsController);

    function UserRightsController(dataservice, log, authenticationService, gettextCatalog, $rootScope, $q, paramHelper) {
        var vm = this;
        $rootScope.vm = vm;

        // Page title
        $rootScope.title = gettextCatalog.getString('UserRights');

        vm.userGroupList = [];
        vm.selectedUserGroup = -1;
        vm.changeEventActive = false;
        vm.defaultUserGroup = undefined;

        // public functions
        vm.userGroupDataBound = userGroupDataBound;
        vm.userGroupSelect = userGroupSelect;
        vm.groupRightsChange = groupRightsChange;

        vm.accessRights = [];
        vm.checkedIndices = [];

        activate();

        function activate() {
            $q.all([authenticationService.getRight('MEGTFELHJOG')]).then(function (results) {
                if (results[0] !== true) {
                    log.permissionError(true);
                } else {
                    getUserGroupDropDownList().then(function () {
                        paramHelper.getParams([], ['usergroupid']).then(function (results) {
                            if (results.usergroupid) {
                                vm.selectedUserGroup = results.usergroupid;
                                vm.defaultUserGroup = results.usergroupid;
                                paramHelper.removeParam('usergroupid');
                            }
                        });
                    });
                }
            });
        }

        // Jogosultsági csoportok lekérése
        function getUserGroupDropDownList() {
            return dataservice.getUserGroupDropDownList()
                .then(function (data) {
                    vm.userGroupList = data.itemsList;
                    angular.element('#userGroupDropDown').ejDropDownList('selectItemByValue', 6);
                });
        }

        // Jogok lekérése
        function getAccessRights() {
            dataservice.getAccessRights().then(function (data) {
                vm.accessRights = data.itemsList;
                getAndSetUserGroupRights(vm.selectedUserGroup);
            });
        }

        function userGroupDataBound() {
            if (vm.defaultUserGroup) {
                angular.element('#userGroupDropDown').ejDropDownList('selectItemByValue', vm.defaultUserGroup);
                vm.selectedUserGroup = vm.defaultUserGroup;
            } else {
                angular.element('#userGroupDropDown').ejDropDownList('selectItemByValue', vm.userGroupList[0].value);
                vm.selectedUserGroup = vm.userGroupList[0].value;
            }
            if (vm.userGroupList.length >= 1) {
                getAccessRights();
            }
        }

        // Jogosultsági csoport kiválasztás esemény
        function userGroupSelect(selectedGroup) {
            vm.changeEventActive = false;
            if (vm.accessRights.length > 0) {
                getAndSetUserGroupRights(selectedGroup.value);
            }
        }

        // Beállított jogosuoltságok lekérése és beállítása
        function getAndSetUserGroupRights(selectedUserGroup) {
            return dataservice.getUserGroupRights(selectedUserGroup).then(function (data) {
                // ListBox takarítása
                ejListBoxEnable();
                vm.checkedIndices = [];
                vm.changeEventActive = false; // change refresh disable

                // Jogosultságok indexének megkeresése
                angular.forEach(data.itemsList, function (right) {
                    vm.checkedIndices.push(vm.accessRights.findIndex(function (item, index) {
                        return item.value === this.IdJog ? index : false;
                    }, right));
                });

                // Jogosultságok beállítása
                angular.element('#groupRightsList').ejListBox({checkedIndices: vm.checkedIndices});
                vm.changeEventActive = true; // change refresh enable
            });
        }

        // ListBox elemek aktiválása
        function ejListBoxEnable() {
            for (var index = 0; index < vm.accessRights.length; index++) {
                angular.element('#groupRightsList').ejListBox('enableItemByIndex', index);
            }
        }

        // Jogosultság checkbox megváltozik
        function groupRightsChange(changed) {
            if (vm.changeEventActive) {
                var shortName = changed.data.shortName;
                var name = changed.text;
                switch (shortName) {
                    case 'SPORTOLO':
                        setSpecialPermission(gettextCatalog.getString('SZULOjog') + ', ' + gettextCatalog.getString('ADMINjog') + ', ' + gettextCatalog.getString('EDZOjog') + ', ' + gettextCatalog.getString('GAZDASAGIjog') + ', ' + gettextCatalog.getString('TECHIGAZGATOjog') + ', ' + ' ' + gettextCatalog.getString('SZAKOSZTVEZjog'), changed);
                        break;
                    case 'SZULO':
                        setSpecialPermission(gettextCatalog.getString('SPORTOLOjog') + ', ' + gettextCatalog.getString('ADMINjog') + ', ' + gettextCatalog.getString('EDZOjog') + ', ' + gettextCatalog.getString('GAZDASAGIjog') + ', ' + gettextCatalog.getString('TECHIGAZGATOjog') + ', ' + ' ' + gettextCatalog.getString('SZAKOSZTVEZjog'), changed);
                        break;
                    case 'ADMIN':
                    case 'EDZO':
                    case 'GAZDASAGI':
                    case 'TECHIGAZGATO':
                    case 'SZAKOSZTVEZ':
                        setSpecialPermission(gettextCatalog.getString('SZULOjog') + ', ' + gettextCatalog.getString('SPORTOLOjog'), changed);
                        break;
                    default:
                        setUserGroupRight(vm.selectedUserGroup, changed);
                }
            }
        }

        function setUserGroupRight(selectedUserGroup, item) {
            vm.changeEventActive = false;
            return dataservice.setUserGroupRight(selectedUserGroup, item.value, item.isChecked).then(function (data) {
                if (data.retValue !== true) {
                    if (item.isChecked) {
                        angular.element('#groupRightsList').ejListBox('uncheckItemByIndex', item.index);
                    } else {
                        angular.element('#groupRightsList').ejListBox('checkItemByIndex', item.index);
                    }
                }
                vm.changeEventActive = true;
            }, function () {
                if (item.isChecked === true) {
                    $('#groupRightsList').ejListBox('uncheckItemByIndex', item.index);
                } else {
                    $('#groupRightsList').ejListBox('checkItemByIndex', item.index);
                }
                vm.changeEventActive = true;
            });
        }

        function setSpecialPermission(name, changed) {
            if (changed.isChecked) {
                swal({
                    title: gettextCatalog.getString('DoYouWantToSetSpecielPermission?'),
                    text: gettextCatalog.getString('YourPermissionWillBe') + ' ' + name,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: gettextCatalog.getString('YesSetPermission'),
                    cancelButtonText: gettextCatalog.getString('Cancel')
                    //closeOnConfirm: false,
                    //closeOnCancel: false
                }).then(function (isConfirm) {
                    if (!isConfirm.value) {
                        angular.element('#groupRightsList').ejListBox('uncheckItemByIndex', changed.index);
                    } else if (isConfirm.value) {
                        angular.element('#groupRightsList').ejListBox('checkItemByIndex', changed.index);
                        setUserGroupRight(vm.selectedUserGroup, changed).then(function () {
                            getAndSetUserGroupRights(vm.selectedUserGroup);
                        });
                    }
                });
            } else {
                setUserGroupRight(vm.selectedUserGroup, changed);
            }
        }
    }
})();
